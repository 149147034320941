<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/perfomance`"
                title="Employee Performance Management"
                :columns="columns"
                routerpath="/hr/addemployeeperformancemanagement"
                :formOptions="formOptions"
                :newPage="true"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      viewFields:[
        {
          label: 'Branch',
          field: 'branch',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Employee',
          field: 'employeeid',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Date',
          field: 'date',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Month',
          field: 'month',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Year',
          field: 'year',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Rating',
          field: 'year',
          type: 'heading',
          class: "col-md-12",
        },
        {
          label: 'Job Knowledge',
          field: 'jobknowledge',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Work Quality',
          field: 'workquality',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Attendance/Punctuality',
          field: 'attendance',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Initiative',
          field: 'initiative',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Communication/Listening Skills',
          field: 'communication',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Goals',
          field: 'goals',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Management Rating',
          field: 'managementrating',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Evaluation',
          field: 'additionalcomment',
          type: 'heading',
          class: "col-md-12",
        },
        {
          label: 'Additional Comments',
          field: 'additionalcomment',
          type: 'text',
          class: "col-md-12",
        },


      ],
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Branch",
          field: "branch",
          type: "dropdown",
          url: "branch",
          responseValue: "name",
          dropdownname:'name',
          filterOptions: {
            enabled: true,
            placeholder: "Branch",
          },
        },
        {
          label: "Employee Name",
          field: "employeeid",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Employee Name",
          },
        },
        {
          label: "Date",
          field: "date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Evaluation",
          field: "additionalcomment",
          filterOptions: {
            enabled: true,
            placeholder: "Search Evaluation",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type:'staticdropdown',
          url: 'getUsers',
          dropdownname: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type:'staticdropdown',
          url: 'getUsers',
          dropdownname: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${
          this.$route.params.id
            ? "Edit Employee Performance"
            : "Add Employee Performance"
        }`,
        submitRouterPath: "/hr/employeeperformancemanagement",
        usersDropdown: true,
        branchDropdown: true,
        designationDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/employeeperformancemanagement`,
        inputFields: [],
        getEditValue: `${baseApi}/getperfomanceById`,

      },
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Employee Performance Management") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
